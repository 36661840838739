// const ORM_APIURL = 'https://gv67yxigc9.execute-api.us-east-2.amazonaws.com/icompliance/';
// const FUNC_APIURL = 'https://hvdfmib1fg.execute-api.us-east-2.amazonaws.com/icompliance/';
const ORM_APIURL = 'https://6rd8kuawa1.execute-api.us-east-2.amazonaws.com/icompliance/';
const FUNC_APIURL = 'https://jj07ha999f.execute-api.us-east-2.amazonaws.com/icompliance/';

export const environment = {
  production: false,
  grupoEconomico    : ORM_APIURL + 'SstGrupoEconomicoFunc',
  sociedad          : ORM_APIURL + 'SstSociedadFunc',
  grupoCentro       : ORM_APIURL + 'SstGrupoCentrosFunc',
  centro            : ORM_APIURL + 'SstCentroFunc',
  rolURL            : ORM_APIURL + 'SstRolFunc',
  tipoIdentificaURL : ORM_APIURL + 'SstTipoIdentificacionFunc',
  moduloURL         : ORM_APIURL + 'SstModuloFunc',
  userURL           : ORM_APIURL + 'SstUsuarioFunc',
  userEntitieRolURL : ORM_APIURL + 'SstUsuarioSociedadRolFunc',
  rolModuloURL      : ORM_APIURL + 'SstRolModuloFunc',
  formatoModuloURL  : ORM_APIURL + 'SstFormatoModuloFunc',
  versionURL        : ORM_APIURL + 'SstVersionFunc',
  normativaURL      : ORM_APIURL + 'SstNormativaFunc',
  userEstOrgURL     : ORM_APIURL + 'SstUsuarioEstOrgFunc',
  eventoURL         : ORM_APIURL + 'SstEventoFunc',
  moduloTreeURL   : FUNC_APIURL + 'sst_ModulosTree',     // actualizado
  entitieAllURL   : FUNC_APIURL + 'sst_GenGrupoEcoAllOrdered', // actualizado
  entitieIdURL    : FUNC_APIURL + 'sst_GenGrupoEcoByIdOrdered',
  modulosByRolURL : FUNC_APIURL + 'sst_ModulosByRol',
  adminForEntitie : FUNC_APIURL + 'sst_AdminForEntitie',
  getUsers        : FUNC_APIURL + 'sst_getUsers',        // actualizado
  bizCreateRol    : FUNC_APIURL + 'sst_BizCreateRol',
  activateUser    : FUNC_APIURL + 'sst_BizActivateUser',
  deleteFormat    : FUNC_APIURL + 'sst_BizDeleteFormat',
  createModule    : FUNC_APIURL + 'sst_BizCreateModule',
  societyDocs     : FUNC_APIURL + 'sst_SocietyDocs',
  S3_URL          : 'https://1soszlmtr1.execute-api.us-east-2.amazonaws.com/icompliance/',
};

export const AWSInfo = {                    // master dev
  s3Bucket: 'dev.icompliance.files',
  groupName: 'icompliance-dev-master',
  urlCompliance: 'dev.icompliance.files/compliance-logo/compliance-logo-141.png'
};

// master
export const cognitoConfig = {              // master dev
  userPool: {
      UserPoolId: 'us-east-2_zB8wSytat',
      ClientId: '6m0vbfeg210n89rapfqfhksnjn',
      region: 'us-east-2'
  },
  identityPoolId: 'us-east-2:606824fd-7f5e-4578-bcd8-f403dae9ba72'
};

// portal
export const cognitoConfigUsers = {         // dev
  userPool: {
      UserPoolId: 'us-east-2_YDTfH7SBL',
      ClientId: '40ldja99an3mgq21nnjl02qhea',
      region: 'us-east-2'
  },
  identityPoolId: 'us-east-2:133e5930-e40a-41db-9868-6d1c391d9773'
};

export const gestion = {
  frontSST  : 1,
  default   : 1
};

export const list = {
  moduloTypes : ['subheading','dropdown','link'],
  eoLabels    : [
    {
      cod: 1,
      name: 'Establecimiento',
      color: 'orange'
    },
    {
      cod: 10,
      name: 'Grupo de Establecimientos',
      color: 'purple'
    },
    {
      cod: 100,
      name: 'Sociedad',
      color: 'cyan'
    },
    {
      cod: 111,
      name: 'Soc-GE-Es',
      color: 'gray'
    }
  ]
};